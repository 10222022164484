import * as nomo_kit from "nomo-webon-kit";
import { KycProofStatus } from "./constants";
import { zenPass, zscSigner } from "./contractUtils";
import { goto } from '$app/navigation';
import { browser } from '$app/environment';
import { api as apiStore } from '$lib/stores/generalStore';
import { get } from 'svelte/store';

const nomo = nomo_kit.nomo;

const apiBaseUrl = import.meta.env.VITE_API;

const KYC_SERVICES_KEY = 'kycServices';
const KYC_SERVICES_TIMESTAMP_KEY = 'kycServicesTimestamp';
const CACHE_DURATION_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

/**
 * Fetches KYC services with caching - only fetches from API once per day
 * @param forceRefresh Set to true to force a fresh API call regardless of cache
 * @returns Promise<any> The KYC services data
 */
export async function getKycServicesWithCache(forceRefresh = false): Promise<any> {
	// Check if we already have services in the store
	const apiStoreValue = get(apiStore);
	if (!forceRefresh && apiStoreValue.kycServices) {
		return apiStoreValue.kycServices;
	}

	// Not in the store, try to get from localStorage
	if (browser && !forceRefresh) {
		try {
			const cachedServices = localStorage.getItem(KYC_SERVICES_KEY);
			const cachedTimestamp = localStorage.getItem(KYC_SERVICES_TIMESTAMP_KEY);

			if (cachedServices && cachedTimestamp) {
				const timestamp = parseInt(cachedTimestamp, 10);
				const now = Date.now();

				// If cache is still valid (less than 24 hours old)
				if (now - timestamp < CACHE_DURATION_MS) {
					const parsedServices = JSON.parse(cachedServices);

					// Update the store
					apiStore.update(store => ({ ...store, kycServices: parsedServices }));
					return parsedServices;
				}
			}
		} catch (error) {
			console.error('Error reading KYC services from cache:', error);
			// Continue to fetch from API if there's an error with cache
		}
	}

	// Not in store or cache is invalid or force refresh, fetch from API
	try {
		const kycServices = await callApi('GET', '/get_kyc_services');

		// Update the store
		apiStore.update(store => ({ ...store, kycServices }));

		// Update the cache in localStorage
		if (browser) {
			localStorage.setItem(KYC_SERVICES_KEY, JSON.stringify(kycServices));
			localStorage.setItem(KYC_SERVICES_TIMESTAMP_KEY, Date.now().toString());
		}

		return kycServices;
	} catch (error) {
		console.error('Error fetching KYC services:', error);
		throw error;
	}
}

export async function fetchKycStatus() {
	try {
		const kycServices = await getKycServicesWithCache();
		const idenfyId = getKycServiceId(kycServices, 'idenfy');
		const sumsubId = getKycServiceId(kycServices, 'sumsub');

		const idenfyInfo = await callApi('GET', '/get_kyc_service_status/' + idenfyId);

		if(idenfyInfo.detail_status === KycProofStatus.SUCCESSFUL && idenfyInfo.address_status === KycProofStatus.SUCCESSFUL) {
			return idenfyInfo;
		} else {
			const homeInfo = await callApi('GET', '/get_kyc_service_status/' + sumsubId);
			return homeInfo;
		}
	} catch (error) {
		console.error('Error fetching KYC status:', error);
		throw error;
	}
}

export const callApi = async (method: "GET" | "POST", endpoint: string, body?: string | null, headers?: {
	[key: string]: string;
}) => {
	console.debug("callApi", method, endpoint, headers, body);
	const http_args: {
		url: string;
		method?: "GET" | "POST";
		headers?: {
			[key: string]: string;
		};
		body?: string;
	} = {
		url: apiBaseUrl + endpoint,
		method: method || 'GET',
		headers: headers || {}
	};

	if(body) {
		http_args.body = body;
	}

	try {
		const resJson = await nomo.authFetch(http_args);

		if (resJson.statusCode === 0) {
			throw new Error('Server unavailable');
		}

		if (resJson.statusCode !== 200) {
			console.log(endpoint, JSON.stringify(resJson, null, 2));
			throw new Error(endpoint + "  API call failed");
		}
		const resObj = await JSON.parse(resJson.response);
		return resObj.data;
	} catch (error) {
		console.error('API call failed:', error);
		if(error?.constructor.name === 'String') {
			if (error.toString().includes('Connection refused')) {
				goto('/server-unavailable');
			}
		}
		throw error;
	}
};

export const getKycServiceId = (services: any[], name: string) => {
	return services.find(service => service.name === name).id;
};

export const handleNftCheck = async (): Promise<boolean> => {
	const address = await zscSigner.getAddress();
	const nftId = await zenPass.nftByAddress(address);
	return nftId.toBigInt() !== 0n;
};

export async function checkServerAvailability(): Promise<boolean> {
	try {
		const response = await fetch(apiBaseUrl + '/health');
		return response.ok;
	} catch (error) {
		console.error('Server availability check failed:', error);
		return false;
	}
}